import React from 'react'

function SvgCenteredWithAppliedViewboxAndScaledSize({ viewBoxWidth = 100, viewBoxHeight = 100, xScaleFactor = 1, yScaleFactor = 1, transform = "", style = {}, children }) {
    const svgStyle = {
        position: 'absolute',
        width: `${viewBoxWidth * xScaleFactor}px`,
        height: `${viewBoxHeight * yScaleFactor}px`,
        transform: transform ? transform : 'translate(-50%, -50%)',
        // zIndex: 99999,
        ...style,
    };

    return (
        <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} style={svgStyle}>
            {children}
        </svg>
    );
}

export default SvgCenteredWithAppliedViewboxAndScaledSize