export const defaultSpringVariations = [
    { angleFromHorizontal: 0, strokeWidth: 1, color: 'red' },
    { angleFromHorizontal: 15, strokeWidth: 2, color: 'orange' },
    { angleFromHorizontal: 30, strokeWidth: 3, color: 'yellow' },
    { angleFromHorizontal: 45, strokeWidth: 4, color: 'green' },
    { angleFromHorizontal: 60, strokeWidth: 5, color: 'blue' },
    { angleFromHorizontal: 75, strokeWidth: 6, color: 'indigo' },

];

export const defaultSpringConstantOptions = {
    angleBiasFromHorizontal: 0,
    // color: 'red',
    // strokeWidth: 8,
    animTimeMs: 400,
    viewBoxWidth: 100,
    viewBoxHeight: 100,
    xScaleFactor: 0.5,
    yScaleFactor: 0.5,
    // timeToDisappearMs: 3500,
}

