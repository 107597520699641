import React from 'react';
import { defaultSpringConstantOptions, defaultSpringVariations } from './svgSpringData';
import SvgCenteredWithAppliedViewboxAndScaledSize from '../SvgCenteredWithAppliedViewboxAndScaledSize';
import { scaleSvgPath } from "../utilities"

const springD = "M3 25 L7 20 S10 15 13 25 S20 35 23 25 S27 15 30 25 S37 35 40 25 S47 15 50 25 S57 35 60 25 S67 15 70 25 S77 35 80 25 S87 15 90 25 S97 35 100 25";
const scaledspringD33Perc = scaleSvgPath(springD, 0.33);
const scaledspringD66Perc = scaleSvgPath(springD, 0.66);

export default React.memo(function SvgSpringAnimateTransform({ constantOptions = {}, variationOptions = {}, variationIndex = 0, transform = "", style = {} }) {
    const mergedConstantOptions = { ...defaultSpringConstantOptions, ...constantOptions };
    const mergedVariationOptions = { ...defaultSpringVariations, ...variationOptions };
    const { animTimeMs, viewBoxWidth, viewBoxHeight, xScaleFactor, yScaleFactor } = mergedConstantOptions;
    const { color, strokeWidth } = mergedVariationOptions[ variationIndex ];


    return (
        <SvgCenteredWithAppliedViewboxAndScaledSize viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth} xScaleFactor={xScaleFactor} yScaleFactor={yScaleFactor} transform={transform} style={style}>
            <g id='springGroupId'>
                <path
                    d={springD}
                    fill="none"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    style={{ transformOrigin: 'left center' }}
                    id='springPathId'
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        values="1,1; 0.3,1; 1,1"
                        begin="0s"
                        dur={`${animTimeMs}ms`}
                        repeatCount="2"
                    />
                </path>
            </g>
        </SvgCenteredWithAppliedViewboxAndScaledSize>
    );
})