import React, { useMemo } from 'react';
import { defaultWaveConstantOptions, defaultWaveVariations } from './svgWaveData';
import SvgCenteredWithAppliedViewboxAndScaledSize from '../SvgCenteredWithAppliedViewboxAndScaledSize';

function createPath(phase, amplitude, offset, frequency) {
    let d = `M0,${50 + amplitude * Math.sin(phase) + offset} `;
    for (let i = 0; i <= 100; i += 25) {
        const cp1x = i + 12.5;
        const cp1y = 50 + amplitude * Math.sin((cp1x * frequency + phase) * Math.PI / 50) + offset;
        const x = i + 25;
        const y = 50 + amplitude * Math.sin((x * frequency + phase) * Math.PI / 50) + offset;
        d += `C${cp1x},${cp1y} ${cp1x},${cp1y} ${x},${y} `;
    }
    return d;
}

export default React.memo(function SvgWave({ constantOptions = {}, variationOptions = {}, variationIndex = 0, transform = "", style = {} }) {
    const mergedConstantOptions = { ...defaultWaveConstantOptions, ...constantOptions };
    const mergedVariationOptions = { ...defaultWaveVariations, ...variationOptions };
    const { animTimeMs, viewBoxWidth, viewBoxHeight, xScaleFactor, yScaleFactor } = mergedConstantOptions;
    const { color, fillColor, strokeWidth, amplitude, frequency, phase, offset } = mergedVariationOptions[ variationIndex ];


    // const d1 = createPath(phase, amplitude, offset);
    // const d2 = createPath(phase - 1.5 * Math.PI, amplitude - 1, offset - 3);
    const d1 = useMemo(() => createPath(phase, amplitude, offset, frequency), [ phase, amplitude, offset, frequency ]);
    const d2 = useMemo(() => createPath(phase - 1.5 * Math.PI, amplitude - 1, offset - 3, frequency), [ phase, amplitude, offset, frequency ]);
    // console.log("phase, amplitude, offset, frequency:", phase, amplitude, offset, frequency);
    // console.log("SvgWave d1:", d1);
    // console.log("SvgWave d2:", d2);
    return (
        <SvgCenteredWithAppliedViewboxAndScaledSize viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth} xScaleFactor={xScaleFactor} yScaleFactor={yScaleFactor} transform={transform} style={style}>
            <g id='wavesGroupId'>
                <path
                    d={d1}
                    fill={fillColor}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    id='wavePathId'
                >
                    <animate
                        attributeName="d"
                        begin="1s"
                        dur={`${animTimeMs}ms`}
                        repeatCount="2"
                        values={`${d1}; ${d2}; ${d1}`}
                    />
                </path>
            </g>
        </SvgCenteredWithAppliedViewboxAndScaledSize>
    );
});