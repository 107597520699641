import React, { useEffect, useRef } from 'react';
import { defaultSpringConstantOptions, defaultSpringVariations } from './svgSpringData';
import SvgCenteredWithAppliedViewboxAndScaledSize from '../SvgCenteredWithAppliedViewboxAndScaledSize';
import { scaleSvgPath, normalizeSvgPathNotHavingCommasIntoWithCommas, convertHorizontalPathToVertical, convertHorizontalPathWithCommasToVertical } from "../utilities"
import { motion, useAnimation, useInView } from 'framer-motion';

const springDWithoutCommas = "M3 25 L7 20 S10 15 13 25 S20 35 23 25 S27 15 30 25 S37 35 40 25 S47 15 50 25 S57 35 60 25 S67 15 70 25 S77 35 80 25 S87 15 90 25 S97 35 100 25";
const springD = normalizeSvgPathNotHavingCommasIntoWithCommas(springDWithoutCommas)
// console.log("springD", springD)
const springD33Perc = scaleSvgPath(springD, 0.33);
const springD66Perc = scaleSvgPath(springD, 0.66);

export default React.memo(function SvgSpring2Horrizontal2Vertical({ constantOptions = {}, variationOptions = {}, variationIndex = 0, transform = "", style = {} }) {
    const mergedConstantOptions = { ...defaultSpringConstantOptions, ...constantOptions };
    const mergedVariationOptions = { ...defaultSpringVariations, ...variationOptions };
    const { animTimeMs, viewBoxWidth, viewBoxHeight, xScaleFactor, yScaleFactor, angleBiasFromHorizontal } = mergedConstantOptions;
    const { color, strokeWidth, angleFromHorizontal } = mergedVariationOptions[ variationIndex ];

    const refWrap = useRef(null)
    const inView = useInView(refWrap)

    const ctrlPath = useAnimation();
    // const rotateZ = angleBiasFromHorizontal + angleFromHorizontal
    // const svgTransform = `${transform} rotateZ(${rotateZ}deg)`
    const svgTransformTop = `${transform} rotateZ(${0}deg)`
    const svgTransformRight = `${transform} rotateZ(${90}deg)`
    const svgTransformBottom = `${transform} rotateZ(${180}deg)`
    const svgTransformLeft = `${transform} rotateZ(${270}deg)`



    useEffect(() => {
        let isMount = true
        if (!inView) {
            ctrlPath.stop()
            return
        }
        const seq1 = async () => {
            // controls.set({ originX: "left" })
            isMount && inView && await ctrlPath.start((i) => ({
                d: [ springD33Perc, springD66Perc, springD ],
                transition: {
                    duration: animTimeMs / 1000,
                    delay: (animTimeMs / 3) * i / 1000
                }
            }));
            isMount && inView && ctrlPath.start({
                scale: [ 1, 0.3, 1 ],
                transition: { duration: animTimeMs / 1000, repeat: 2 }
            });
        };
        seq1()
        return () => {
            isMount = false
            ctrlPath.stop()
        }
    }
        , [ inView, ctrlPath, animTimeMs ]);

    return (<>
        <SvgCenteredWithAppliedViewboxAndScaledSize
            viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth}

            xScaleFactor={xScaleFactor}
            yScaleFactor={yScaleFactor}
            transform={svgTransformTop}
            style={style}
            key="svgTop"
        >
            <motion.path
                ref={refWrap}
                d={springD33Perc}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                // style={{ transformOrigin: 'left center' }}
                key='springPathTop'

                animate={ctrlPath}
            />
        </SvgCenteredWithAppliedViewboxAndScaledSize>
        <SvgCenteredWithAppliedViewboxAndScaledSize
            viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth}
            xScaleFactor={xScaleFactor} yScaleFactor={yScaleFactor}
            transform={svgTransformRight}
            style={style}
            key='svgRight'
        >
            <motion.path
                d={springD33Perc}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                // style={{ transformOrigin: 'left center' }}

                key='springPathRight'
                animate={ctrlPath}
            />
        </SvgCenteredWithAppliedViewboxAndScaledSize>
        <SvgCenteredWithAppliedViewboxAndScaledSize viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth}
            xScaleFactor={xScaleFactor} yScaleFactor={yScaleFactor}
            transform={svgTransformBottom}
            style={style}
            key='svgBottom'
        >
            <motion.path
                d={springD33Perc}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                // style={{ transformOrigin: 'left center' }}

                key='springPathBottom'
                animate={ctrlPath}
            />
        </SvgCenteredWithAppliedViewboxAndScaledSize>
        <SvgCenteredWithAppliedViewboxAndScaledSize viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth}
            xScaleFactor={xScaleFactor} yScaleFactor={yScaleFactor}
            transform={svgTransformLeft}
            style={style}
            ke='svgLeft'
        >
            <motion.path
                d={springD33Perc}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                // style={{ transformOrigin: 'left center' }}

                key='springPathLeft'
                animate={ctrlPath}
            />
        </SvgCenteredWithAppliedViewboxAndScaledSize>
    </>);
})