import React, { useEffect, useRef, useState, useCallback, useMemo, Profiler } from 'react';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { getMousePositionFromEvent } from './utilities.js';
import SvgFlower3 from './svgFlower/SvgFlower3.js';
import { defaultAnimatedSvgInsideParentBoundingBox2Options } from './svgImagesData.js';
import { defaultSparkVariations } from "./svgSpark/svgSparkData.js";
import { defaultFlowerVariations } from './svgFlower/svgFlowerData.js';
import SvgSpark from './svgSpark/SvgSpark.js';
import SvgSpring from './svgSpring/SvgSpring.js';
import SvgSpring2Horizontal2Vertical from "./svgSpring/SvgSpring2Horizontal2Vertical.js"
import SvgSpringAnimateTransform from "./svgSpring/SvgSpringAnimateTransform.js"
import { defaultSpringVariations } from './svgSpring/svgSpringData.js';
import SvgWave from './svgWave/SvgWave.js';
import { defaultWaveVariations } from './svgWave/svgWaveData.js';
import SvgHouse from './svgHouse/SvgHouse.js';
import { defaultHouseVariations } from './svgHouse/svgHouseData.js';
import { Typography } from '@mui/material';



// const onRenderCallback = (
//     id, // the "id" prop of the Profiler tree that has just committed
//     phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
//     actualDuration, // time spent rendering the committed update
//     baseDuration, // estimated time to render the entire subtree without memoization
//     startTime, // when React began rendering this update
//     commitTime, // when React committed this update
//     interactions // the Set of interactions belonging to this update
// ) => {
//     console.log(id, phase, Math.round(actualDuration, 1), Math.round(baseDuration, 1), Math.round(startTime), Math.round(commitTime), interactions);
// };

const styleSvgBase = {
    position: 'absolute',
};
const mapSvgComponents = {
    SvgHouse: {
        component: SvgHouse,
        variations: defaultHouseVariations,
    },
    SvgSpark: {
        component: SvgSpark,
        variations: defaultSparkVariations,
    },
    SvgFlower3: {
        component: SvgFlower3,
        variations: defaultFlowerVariations,
    },
    SvgSpring: {
        component: SvgSpring,
        variations: defaultSpringVariations,
    },
    SvgSpring2Horrizontal2Vertical: {
        component: SvgSpring2Horizontal2Vertical,
        variations: defaultSpringVariations,
    },
    SvgSpringAnimateTransform: {
        component: SvgSpringAnimateTransform,
        variations: defaultSpringVariations,
    },
    SvgWave: {
        component: SvgWave,
        variations: defaultWaveVariations,
    }
}

const svgVariations = {
    SvgHouse: defaultHouseVariations.length,
    SvgSpark: defaultSparkVariations.length,
    SvgFlower3: defaultFlowerVariations.length,
    SvgSpring: defaultSpringVariations.length,
    SvgSpring2Horizontal2Vertical: defaultSpringVariations.length,
    SvgSpringAnimateTransform: defaultSpringVariations.length,
    SvgWave: defaultWaveVariations.length,
};

function SvgCreatedFromMouseMove({ x, y, id, variationIndex, selectedSvgComponentName }) {
    const transformMoveValue = useMemo(() => (
        `translateX(${x}px) translateY(${y}px) translate(-50%, -50%)`
    ), [ x, y ]);

    const { component: SelectedSvgComponent, variations } = mapSvgComponents[ selectedSvgComponentName ];

    return (
        <div style={{ ...styleSvgBase, transform: transformMoveValue }} key={id}>
            <SelectedSvgComponent variationIndex={variationIndex} />
        </div>
    )
}
const svgStyleInButton = { width: "100%", height: "100%", padding: "5px", transform: "unset" }

const PaintSvgWithMouse = ({ options = defaultAnimatedSvgInsideParentBoundingBox2Options }) => {
    const heightPaintingCanvas = options.heightPaintingCanvas;
    const [ hasHover, setHasHover ] = useState(false);


    const refWrap = useRef(null);
    const refHandleMouseMoveAnimationFrameId = useRef(null);
    const [ isDrawingEnabled, setIsDrawingEnabled ] = useState(false); // Add this line

    const [ refWrapWidth, setRefWrapWidth ] = useState(0);
    const [ refWrapHeight, setRefWrapHeight ] = useState(0);
    const [ refWrapLeft, setRefWrapLeft ] = useState(0);
    const [ refWrapTop, setRefWrapTop ] = useState(0);
    const renderCount = useRef(0);


    const [ svgUnitsFromMouseMove, setSvgUnitsFromMouseMove ] = useState([]);
    const [ selectedSvgComponentName, setSelectedSvgComponentName ] = useState('SvgHouse');

    const handleSvgSelection = (svgName) => {
        setSelectedSvgComponentName(svgName);
    }
    const handleMouseMoveEvent = useCallback((event) => {
        if (!isDrawingEnabled) return; // Add thi
        const { clientX, clientY } = event;
        const { left, top, width, height } = refWrap.current.getBoundingClientRect();

        const isInsideRefWrap = clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height;

        if (!isInsideRefWrap) return;

        refHandleMouseMoveAnimationFrameId.current = requestAnimationFrame(() => {
            const { mouseX, mouseY } = getMousePositionFromEvent(event, refWrapLeft, refWrapTop);

            setSvgUnitsFromMouseMove(prevUnits => [
                ...prevUnits,
                {
                    id: prevUnits.length,
                    x: mouseX,
                    y: mouseY,
                    variationIndex: prevUnits.length % mapSvgComponents[ selectedSvgComponentName ].variations.length
                }
            ]);
        });
    }, [ isDrawingEnabled, refWrapLeft, refWrapTop, selectedSvgComponentName ]);

    const clearDrawing = () => {
        setSvgUnitsFromMouseMove([]);
    }

    const setRefBoxBoundingBoxValues = useCallback(() => {
        const { width, height, left, top, } = refWrap.current.getBoundingClientRect();
        if (width === 0 || height === 0) return;
        setRefWrapHeight(height);
        setRefWrapWidth(width);
        setRefWrapLeft(left);
        setRefWrapTop(top);
    }, []);

    useEffect(() => {
        if (refWrap.current && !refWrapWidth) {
            setRefBoxBoundingBoxValues();
        }
    }, [ refWrap, refWrapWidth, setRefBoxBoundingBoxValues ]);

    useEffect(() => {
        if (!refWrap.current) return;
        const refWrapValue = refWrap.current;
        refWrap.current.addEventListener("mousemove", handleMouseMoveEvent);
        refWrap.current.addEventListener("touchmove", handleMouseMoveEvent, { passive: true });

        return () => {
            if (refHandleMouseMoveAnimationFrameId.current) {
                cancelAnimationFrame(refHandleMouseMoveAnimationFrameId.current);
            }

            refWrapValue.removeEventListener("mousemove", handleMouseMoveEvent);
            refWrapValue.removeEventListener("touchmove", handleMouseMoveEvent);
        };
    }, [ refWrap, handleMouseMoveEvent ]);
    // useEffect(() => {
    //     renderCount.current = renderCount.current + 1;
    //     console.log(`Painted ${renderCount.current} times`);
    // });
    useEffect(() => {
        if (window.matchMedia("(hover: hover)").matches) {
            setHasHover(true);
        }
    }, []);
    // console.log("hasHover", hasHover)
    // if (!hasHover) {
    //     return null; // or return a different component for touch devices
    // }
    return (
        <>
            <Typography variant="h5" component="h5" gutterBottom>
                Paint with the Mouse
            </Typography>
            {/* <Profiler id="PaintSvgWithMouse" onRender={onRenderCallback}> */}
            <Box
                ref={refWrap}
                sx={{
                    width: "100%",
                    height: `${heightPaintingCanvas}px`, // "100%",
                    position: "relative",
                    backgroundColor: "white",
                    marginBottom: "20px",
                }}
            >
                {svgUnitsFromMouseMove.map(({ x, y, id, variationIndex }) => (
                    <SvgCreatedFromMouseMove x={x} y={y} variationIndex={variationIndex} selectedSvgComponentName={selectedSvgComponentName} key={id} />
                ))}

                <motion.svg
                    id="svgCircle"
                    preserveAspectRatio="none"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                />
            </Box>

            <Stack direction="row" spacing={2}
                sx={{ display: { xs: 'none', sm: 'flex' }, }}
            >
                <Button variant="contained"
                    onClick={() => setIsDrawingEnabled(!isDrawingEnabled)}
                    sx={{ fontSize: 'small' }}
                >
                    {isDrawingEnabled ? 'Disable Painting' : 'Enable Painting'}
                </Button>
                <Button
                    variant="contained"
                    onClick={clearDrawing}
                    sx={{ fontSize: 'small' }}
                >
                    Clear
                </Button>
            </Stack>
            <ButtonGroup
                variant="text"
                aria-label="button group"
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: 'center',
                    height: "50px",
                    width: '100%', // Ensures the ButtonGroup takes the full width of its container
                    // flexDirection: { xs: 'column', sm: 'row' }, // Vertical on xs screens, horizontal on sm and up
                    '& .MuiButton-root': { // Applies styles to each Button within the ButtonGroup
                        width: { xs: '100%', sm: 'auto' }, // Full width on xs screens, auto width on sm and up
                        mb: { xs: 1, sm: 0 }, // Adds bottom margin on xs screens, no margin on sm and up
                    },
                }}
            >
                <Button sx={{ position: "relative", bgcolor: "white" }} onClick={() => handleSvgSelection('SvgHouse')}><SvgHouse style={svgStyleInButton} /></Button>
                <Button sx={{ position: "relative", bgcolor: "white" }} onClick={() => handleSvgSelection('SvgSpark')}><SvgSpark style={svgStyleInButton} /></Button>
                <Button sx={{ position: "relative", bgcolor: "white" }} onClick={() => handleSvgSelection('SvgFlower3')}><SvgFlower3 style={svgStyleInButton} /></Button>
                <Button sx={{ position: "relative", bgcolor: "white" }} onClick={() => handleSvgSelection('SvgSpring')}><SvgSpring style={svgStyleInButton} /></Button>
                <Button sx={{ position: "relative", bgcolor: "white" }} onClick={() => handleSvgSelection('SvgSpring2Horrizontal2Vertical')}><SvgSpring2Horizontal2Vertical style={svgStyleInButton} /></Button>
                <Button sx={{ position: "relative", bgcolor: "white" }} onClick={() => handleSvgSelection('SvgSpringAnimateTransform')}><SvgSpringAnimateTransform style={svgStyleInButton} /></Button>
                <Button sx={{ position: "relative", bgcolor: "white" }} onClick={() => handleSvgSelection('SvgWave')}><SvgWave style={svgStyleInButton} /></Button>
            </ButtonGroup>
            {/* </Profiler> */}
        </>
    );
}

export default PaintSvgWithMouse;

// const [ svgSpark, sparkGroupId ] = createSvgSpark({ variationIndex })

// return (
//     <div style={{ ...styleSvgBase, transform: transformMoveValue }} key={id}>
//         {/* <SvgWave variationIndex={variationIndex} /> */}
//         <SvgSpring variationIndex={variationIndex} />
//         {/* <SvgFlower3 variationIndex={variationIndex} /> */}
//         {/* <SvgSpark variationIndex={variationIndex} /> */}
//         {/* <SvgHouse variationIndex={variationIndex} /> */}
//     </div>