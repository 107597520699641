import React from 'react'

function Stem({ vbWidthHalf, vbHeightHalf, stemHeight, stemColor }) {
    return (
        <rect
            x={vbWidthHalf - 2}
            y={vbWidthHalf}
            width="2"
            height={stemHeight}
            strokeLinecap="round"
            strokeWidth="1"
            stroke={stemColor}
            fill="none"
        />
    );
}

export default Stem