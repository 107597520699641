import React from 'react'

function Petal({ vbWidthHalf, vbHeightHalf, petalWidth, petalHeight, flowerColor, rotation, animTimeMs }) {
    return (
        <ellipse
            cx={vbWidthHalf}
            cy={vbHeightHalf}
            rx={petalWidth / 2}
            ry={petalHeight / 2 + 10}
            stroke={flowerColor}
            strokeLinecap="round"
            strokeWidth="1"
            fill="none"
            transform={`rotate(${rotation} ${vbWidthHalf} ${vbHeightHalf})`}
        >
            <animate
                attributeName="rx"
                values={`${petalWidth / 2}; ${petalWidth / 2 + 10}; ${petalWidth / 2}`}
                dur={`${animTimeMs}ms`}
                repeatCount="0"
                fill="freeze"
            />
            <animate
                attributeName="ry"
                values={`${petalHeight / 2 + 10}; ${petalHeight / 2}; ${petalHeight / 2 + 10}`}
                dur={`${animTimeMs}ms`}
                repeatCount="0"
                fill="freeze"
            />
        </ellipse>
    );
}

export default Petal