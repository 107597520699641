export const defaultFlowerVariations = [
    { petalWidthVariation: 0, petalHeightVariation: 0, leafWidthVariation: 0, leafHeightVariation: 0, rotationVariation: 0, flowerColor: 'red' },
    { petalWidthVariation: 1, petalHeightVariation: 1, leafWidthVariation: 1, leafHeightVariation: 1, rotationVariation: 10, flowerColor: 'orange' },
    { petalWidthVariation: 2, petalHeightVariation: 2, leafWidthVariation: 2, leafHeightVariation: 2, rotationVariation: 20, flowerColor: 'yellow' },
    { petalWidthVariation: 3, petalHeightVariation: 3, leafWidthVariation: 3, leafHeightVariation: 3, rotationVariation: 30, flowerColor: 'green' },
    { petalWidthVariation: 4, petalHeightVariation: 4, leafWidthVariation: 4, leafHeightVariation: 4, rotationVariation: 40, flowerColor: 'blue' },
    { petalWidthVariation: 5, petalHeightVariation: 5, leafWidthVariation: 5, leafHeightVariation: 5, rotationVariation: 50, flowerColor: 'indigo' },
];

export const defaultFlowerConstantOptions = {
    color: 'random', // default to random color
    petalWidth: 10,
    petalHeight: 40,
    petalCount: 8,
    animTimeMs: 93500,
    viewBoxWidth: 100,
    viewBoxHeight: 100, // Updated to give space for the stem and leaves
    stemHeight: 70,
    leafWidth: 20,
    leafHeight: 10,
    // timeToDisappearMs: 3500,
    xScaleFactor: 0.5,
    yScaleFactor: 1,
};
