import { createSvgFlower } from './svgFlower/createSvgFlower'
import { createSvgSpark } from './svgSpark/createSvgSpark'



export const svgImageConfigPool = {
    flower: {
        createFunc: createSvgFlower,
        properties: {
            color: 'random', // default to random color
            petalWidth: 10,
            petalHeight: 40,
            petalCount: 8,
            animTimeMs: 3500,
            viewBoxWidth: 100,
            viewBoxHeight: 100, // Updated to give space for the stem and leaves
            stemHeight: 70,
            leafWidth: 20,
            leafHeight: 10,
            // timeToDisappearMs: 3500,
            xScaleFactor: 0.5,
            yScaleFactor: 1,
        },
    },
    spark: {
        createFunc: createSvgSpark,
        properties: {
            color: 'random',
            hw: 30,
            unitsNumber: 8,
            sw: 8,
            animTimeMs: 400,
            viewBoxWidth: 100,
            viewBoxHeight: 100,
            xScaleFactor: 0.2,
            yScaleFactor: 0.2,
            // timeToDisappearMs: 3500,
        },
    },

}




export const getRotation = (count) => {
    let rotation
    switch (count) {
        case 0: rotation = -6; break;   // 9 o'clock
        case 1: rotation = -3; break;  // 12 o'clock
        case 2: rotation = 0; break;  // 12 o'clock
        case 3: rotation = 3; break; // 3 o'clock
        case 4: rotation = 6; break; // 6 o'clock
        // case 3: rotation = 0; break; // 6 o'clock
        // case 3: rotation = 270; break; // 6 o'clock
    }
    return rotation
}


const svgFrameStylesConfigPoll = {
    parentPaddingFrames: [ 'topPaddingFrameOfParent', 'rightPaddingFrameOfParent', 'bottomPaddingFrameOfParent', "leftPaddingFrameOfParent" ],
    padding50Frames: [ 'topFrameW50', 'rightFrameW50', 'bottomFrameW50', "leftFrameW50" ],
}

export const svgStylesConfigPoll = {
    fillSpace: {
        style: { // width: refBox.current ? `${refBox.current.offsetWidth}px` : '0px',
            width: 'inherit',
            height: 'inherit',
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            left: 0,
            top: 0,
            transform: `skew(${(Date.now() % 20) + Math.random() * 20}deg, ${(Date.now() % 20) + Math.random() * 20}deg)`
        },
        // transform: `skew(${Math.random() * 20}deg, ${Math.random() * 20}deg)`,
        // transform: (mouseX, mouseY) => `skew(${mouseX % 20}deg, ${mouseY % 20}deg)`,
    },
    // frame: [ 'topPaddingFrameOfParent','rightPaddingFrameOfParent','bottomPaddingFrameOfParent', "leftPaddingFrameOfParent"],
    leftPaddingFrameOfParent: {
        style: {
            left: 0,
            top: 0,
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            height: 'inherit',
            transform: 'none',
        },
        // width: (pLeft) => `${pLeft}px`,
    },
    rightPaddingFrameOfParent: {
        style: {
            right: 0,
            top: 0,
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            height: 'inherit',
            transform: 'none',
        },
        // width: (pLeft) => `${pLeft}px`,
    },
    topPaddingFrameOfParent: {
        style: {
            left: 0,
            top: 0,
            // height: '50px',
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            width: 'inherit',
            transform: 'none',
        },
    },
    bottomPaddingFrameOfParent: {
        style: {
            left: 0,
            bottom: 0,
            // height: '50px',
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            width: 'inherit',
            transform: 'none',
        },
    },
    leftFrameW50: {
        style: {
            left: 0,
            top: 0,
            width: '50px',
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            height: 'inherit',
            transform: 'none',
        },
    },
    rightFrameW50: {
        style: {
            right: 0,
            top: 0,
            width: '50px',
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            height: 'inherit',
            transform: 'none',
        },
    },
    topFrameW50: {
        style: {
            left: 0,
            top: 0,
            height: '50px',
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            width: 'inherit',
            transform: 'none',
        },
    },
    bottomFrameW50: {
        style: {
            left: 0,
            bottom: 0,
            height: '50px',
            // height: refBox.current ? `${refBox.current.offsetHeight}px` : '0px',
            width: 'inherit',
            transform: 'none',
        },
        // transform: (mouseX, mouseY) => 'rotateZ(180deg)',
    },
}


const getSvgStyleConfigured = (config = "fillSpace") => {
    return svgStylesConfigPoll[ config ].style
}
//  const getSvgTransformConfigured = ({ config = "fillSpace", mouseX, mouseY }) => {
const getSvgTransformConfiguredFunc = (config = "fillSpace") => {
    return svgStylesConfigPoll[ config ].transform
}

export const defaultAnimatedSvgInsideParentBoundingBox2Options = {
    heightPaintingCanvas: 400,
    isFrames: true,
    framesOptions: {
        frames: svgFrameStylesConfigPoll.parentPaddingFrames,
        svgImagesInFrames: [ 'spark', 'flower', 'spark', 'flower' ], // svgImageConfigPool
    },
    isFramesForClick: true,
    framesOptionsForClick: {
        framesForClick: svgFrameStylesConfigPoll.parentPaddingFrames,
        svgImagesInFramesForClick: [ 'flower', 'spark', 'flower', 'spark' ], // svgImageConfigPool
    },
    isCreateAnimatingSvgInIntervals: true,
    animatingPath: "waveBoxPath"
    // svgImageConfigPool
}

export const createSvgForFrame = (frameStyle, svgImage) => {
    const svgCreateFunc = svgImageConfigPool[ svgImage ].createFunc //createFlowerConfigured(config);
    const [ svgCreated, svgGroupId ] = svgCreateFunc()
    svgCreated.setAttribute('preserveAspectRatio', 'none');
    const svgStyle = getSvgStyleConfigured(frameStyle);
    Object.assign(svgCreated.style, svgStyle);
    return svgCreated
};





