import React from 'react'

function FlowerGroup({ children }) {
    return (
        <g id='flowerGroupId'>
            {children}
        </g>
    );
}

export default FlowerGroup