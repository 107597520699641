export const defaultWaveVariations = [
    { amplitude: 6, frequency: 0.6, phase: 0, offset: 0, fillColor: '#1E90FF', strokeWidth: 1, color: 'red' },
    { amplitude: 8, frequency: 0.8, phase: 5, offset: 5, fillColor: '#00BFFF', strokeWidth: 2, color: 'orange' },
    { amplitude: 10, frequency: 1, phase: 0, offset: 10, fillColor: '#40E0D0', strokeWidth: 3, color: 'yellow' },
    { amplitude: 12, frequency: 1.2, phase: 5, offset: 15, fillColor: '#20B2AA', strokeWidth: 3, color: 'green' },
    { amplitude: 14, frequency: 1.4, phase: 0, offset: 20, fillColor: '#48D1CC', strokeWidth: 2, color: 'blue' },
    { amplitude: 16, frequency: 1.6, phase: 5, offset: 25, fillColor: '#00CED1', strokeWidth: 1, color: 'indigo' },
];

export const defaultWaveConstantOptions = {
    // color: 'red',
    // strokeWidth: 8,
    animTimeMs: 400,
    viewBoxWidth: 100,
    viewBoxHeight: 100,
    xScaleFactor: 0.5,
    yScaleFactor: 0.5,
    // timeToDisappearMs: 3500,
}

