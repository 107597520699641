import React, { useMemo, useCallback } from 'react'
import Stem from './Stem';
import Leaf from './Leaf';
import Petal from './Petal';
import SvgCenteredWithAppliedViewboxAndScaledSize from '../SvgCenteredWithAppliedViewboxAndScaledSize.js';
import FlowerGroup from './FlowerGroup';
import { defaultFlowerConstantOptions, defaultFlowerVariations } from './svgFlowerData.js';


const stemColor = "green";

const SvgFlower3 = React.memo(function SvgFlower3({ constantOptions = {}, variationOptions = {}, variationIndex = 0, transform = "", style = {} }) {
    const mergedConstantOptions = useMemo(() => ({ ...defaultFlowerConstantOptions, ...constantOptions }), [ constantOptions ]);
    const mergedVariationOptions = useMemo(() => ({ ...defaultFlowerVariations, ...variationOptions }), [ variationOptions ]);
    const { petalWidth, petalHeight, petalCount, animTimeMs, timeToDisappearMs, viewBoxWidth, viewBoxHeight, stemHeight, leafWidth, leafHeight, xScaleFactor, yScaleFactor } = mergedConstantOptions;
    const { petalWidthVariation, petalHeightVariation, leafWidthVariation, leafHeightVariation, rotationVariation, flowerColor } = mergedVariationOptions[ variationIndex ];
    const vbWidthHalf = useMemo(() => viewBoxWidth / 2, [ viewBoxWidth ]);
    const vbHeightHalf = useMemo(() => viewBoxHeight / 2, [ viewBoxHeight ]);

    const leafPaths = useMemo(() => [
        `M${vbWidthHalf} ${vbHeightHalf + stemHeight / 3} q${leafWidth + leafWidthVariation} -${leafHeight * 2 + leafHeightVariation} ${leafWidth * 2 + leafWidthVariation} 0`,
        `M${vbWidthHalf} ${vbHeightHalf + stemHeight / 3} q-${leafWidth + leafWidthVariation} -${leafHeight * 2 + leafHeightVariation} -${leafWidth * 2 + leafWidthVariation} 0`
    ], [ vbWidthHalf, vbHeightHalf, stemHeight, leafWidth, leafWidthVariation, leafHeight, leafHeightVariation ]);

    const stem = useMemo(() => <Stem vbWidthHalf={vbWidthHalf} vbHeightHalf={vbHeightHalf} stemHeight={stemHeight} stemColor={stemColor} />, [ vbWidthHalf, vbHeightHalf, stemHeight ]);

    const leaves = useMemo(() => leafPaths.map((path, i) => <Leaf vbWidthHalf={vbWidthHalf} vbHeightHalf={vbHeightHalf} stemHeight={stemHeight} leafWidth={leafWidth} leafHeight={leafHeight} stemColor={stemColor} path={path} key={i} />), [ leafPaths, vbWidthHalf, vbHeightHalf, stemHeight, leafWidth, leafHeight, stemColor ]);

    const createPetal = useCallback((_, i) => {
        const rotation = (360 / petalCount) * i + rotationVariation;
        const randomPetalWidth = petalWidth + petalWidthVariation;
        const randomPetalHeight = petalHeight + petalHeightVariation;

        return <Petal vbWidthHalf={vbWidthHalf} vbHeightHalf={vbHeightHalf} petalWidth={randomPetalWidth} petalHeight={randomPetalHeight} flowerColor={flowerColor} rotation={rotation} animTimeMs={animTimeMs} key={i} />;
    }, [ petalCount, rotationVariation, petalWidth, petalWidthVariation, petalHeight, petalHeightVariation, vbWidthHalf, vbHeightHalf, flowerColor, animTimeMs ]);

    const petals = useMemo(() => Array.from({ length: petalCount }).map(createPetal), [ petalCount, createPetal ]);

    return (
        <SvgCenteredWithAppliedViewboxAndScaledSize viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth} xScaleFactor={xScaleFactor} yScaleFactor={yScaleFactor} transform={transform} style={style}>
            <FlowerGroup>
                {stem}
                {leaves}
                {petals}
            </FlowerGroup>
        </SvgCenteredWithAppliedViewboxAndScaledSize>
    );
});

export default SvgFlower3



