import React, { useEffect, useMemo, memo, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { defaultHouseConstantOptionsWithSeconds, defaultHouseVariations } from './svgHouseData';
import SvgCenteredWithAppliedViewboxAndScaledSize from '../SvgCenteredWithAppliedViewboxAndScaledSize.js';

export default memo(function SvgHouse({ constantOptions = {}, variationOptions = {}, variationIndex = 0, transform = "", style = {} }) {
    const mergedConstantOptions = { ...defaultHouseConstantOptionsWithSeconds, ...constantOptions };
    const mergedVariationOptions = { ...defaultHouseVariations, ...variationOptions };
    const { roofColor, roofStrokeColor, windowColor, windowHeight, windowWidth, frameColor, chimneyHeight, chimneyWidth, chimneyStrokeColor, chimneyColor, doorWidth, doorHeight, doorColor, handleColor, doorAnimTimeS, doorAnimDelayS, smokeAnimTimeS, smokeAnimDelayS, smokeColor, animTimeS, animDelayS, viewBoxWidth, viewBoxHeight, xScaleFactor, yScaleFactor } = mergedConstantOptions;
    const { wallWidth, wallHeight, strokeWidth, wallColor } = mergedVariationOptions[ variationIndex ];

    const refWrap = useRef(null)
    const inView = useInView(refWrap)
    const controls = useAnimation();
    const controlsSmoke = useAnimation();

    useEffect(() => {
        let isMounted = true; // Add this line
        if (!inView) {
            controlsSmoke.stop()
            controls.stop()
            return
        }
        async function seq1() {
            isMounted && inView && await controlsSmoke.start(i => ({
                // cy: [ `${20 - i * 10}`, "0" ],
                cy: [ 20 - i * 10, 0 ],
                opacity: [ 1, 0 ],
                transition: { duration: smokeAnimTimeS, delay: smokeAnimDelayS * i, loop: 1 }
            }));
            isMounted && inView && controls.start({
                scale: [ 1, 2, 1 ],
                transition: { duration: animTimeS, delay: animDelayS, loop: 1 }
            });
        }
        seq1()
        return () => {
            isMounted = false;
            controlsSmoke.stop()
            controls.stop()
        }; // Clean up function to set isMounted to false when the component unmounts
    }, [ inView, controls, controlsSmoke, animTimeS, animDelayS, smokeAnimTimeS, smokeAnimDelayS ]);

    const smokeParticles = useMemo(() => Array.from({ length: 4 }).map((_, i) => (
        <motion.circle
            key={i}
            custom={i}
            cx={`${60 + i * 15}`} // Adjust the x-coordinate based on the index
            cy={`${20 - i * 10}`} // Adjust the y-coordinate based on the index
            r={`${2 + i * 2}`} // Adjust the radius based on the index
            fill={smokeColor}
            animate={controlsSmoke}
            style={{ opacity: 1 }}
        />
    )), [ controlsSmoke, smokeColor ]);
    const doorY = 40 + wallHeight - doorHeight;
    const doorX = 20 + wallWidth - doorWidth - 2;
    const chimneyY = 20 + wallHeight - chimneyHeight;
    const doorHandleY = doorY + doorHeight / 2;
    const doorHandleX = doorX + 2;
    return (
        <SvgCenteredWithAppliedViewboxAndScaledSize
            viewBoxHeight={viewBoxHeight + 20}
            viewBoxWidth={viewBoxWidth + 20} xScaleFactor={xScaleFactor}
            yScaleFactor={yScaleFactor} transform={transform} style={style}
        >

            <motion.g
                id="smokeGroupId"
                animate={controls}>
                {smokeParticles}
            </motion.g>
            <motion.g
                ref={refWrap}
                id="houseGroupId"
                animate={controls}>
                <rect x="20" y="40" width={wallWidth} height={wallHeight} fill={wallColor} strokeWidth={strokeWidth} stroke="black" />
                {/* <polygon points="20,40 50,10 80,40" fill={roofColor} stroke={roofStrokeColor} /> */}
                <polygon points="10,40 50,10 90,40" fill={roofColor} stroke={roofStrokeColor} />
                <rect x="30" y="50" width={windowWidth} height={windowHeight} fill={windowColor} />
                <path d="M 30 50 v 20 h 20 v -20 z M 40 50 v 20 M 30 60 h 20" stroke={frameColor} fill="transparent" />
                <rect x="60" y="20" width={chimneyWidth} height={chimneyHeight} fill={chimneyColor} stroke={chimneyStrokeColor} />
                <g>
                    <rect x={doorX} y={doorY} width={doorWidth} height={doorHeight} fill={doorColor} stroke={"black"} strokeWidth={1} />
                    <circle cx={doorHandleX} cy={doorHandleY} r="2" fill={handleColor} />
                    <motion.animateTransform attributeName="transform" type="rotate"
                        animate={{ rotate: [ 0, 10, 0 ] }}
                        transition={{ duration: doorAnimTimeS, delay: doorAnimDelayS, loop: 1 }} />
                </g>
            </motion.g>
        </SvgCenteredWithAppliedViewboxAndScaledSize>
    );
})