import React, { useMemo, useRef } from 'react';
import { defaultSparkConstantOptions, defaultSparkVariations } from './svgSparkData';
import SvgCenteredWithAppliedViewboxAndScaledSize from '../SvgCenteredWithAppliedViewboxAndScaledSize';
import { motion, useAnimation, useInView } from 'framer-motion';
import { useEffect } from 'react';

const unitsArray = Array.from({ length: defaultSparkVariations.length });

export default React.memo(function SvgSpark({ constantOptions = {}, variationOptions = {}, variationIndex = 0, transform = "", style = {} }) {
    const mergedConstantOptions = { ...defaultSparkConstantOptions, ...constantOptions };
    const mergedVariationOptions = { ...defaultSparkVariations, ...variationOptions };
    const { hw, animTimeMs, viewBoxWidth, viewBoxHeight, xScaleFactor, yScaleFactor } = mergedConstantOptions;
    const { color, unitsNumber, strokeWidth } = mergedVariationOptions[ variationIndex ];

    const refWrap = useRef(null)
    const inView = useInView(refWrap)
    const vbWidthHalf = useMemo(() => viewBoxWidth / 2, [ viewBoxWidth ]);
    const vbHeightHalf = useMemo(() => viewBoxHeight / 2, [ viewBoxHeight ]);
    // const unitPathAnimatingValues = useMemo(() => `M${vbWidthHalf} ${vbHeightHalf} ${vbWidthHalf} ${vbHeightHalf - strokeWidth / 2}; M${vbWidthHalf} ${vbHeightHalf - strokeWidth} ${vbWidthHalf} ${strokeWidth / 2}; M${vbWidthHalf} ${strokeWidth / 2} ${vbWidthHalf} ${strokeWidth / 2} `, [ vbWidthHalf, vbHeightHalf, strokeWidth ]);
    const unitPathAnimatingValues = useMemo(() => [ `M${vbWidthHalf} ${vbHeightHalf} ${vbWidthHalf} ${vbHeightHalf - strokeWidth / 2}`, `M${vbWidthHalf} ${vbHeightHalf - strokeWidth} ${vbWidthHalf} ${strokeWidth / 2}`, `M${vbWidthHalf} ${strokeWidth / 2} ${vbWidthHalf} ${strokeWidth / 2}` ], [ vbWidthHalf, vbHeightHalf, strokeWidth ]);
    // console.log("spark unitPathAnimatingValues:", unitPathAnimatingValues);
    const unitD = useMemo(() => `M${vbWidthHalf} ${vbHeightHalf} ${vbWidthHalf} ${vbHeightHalf - strokeWidth / 2}`, [ vbWidthHalf, vbHeightHalf, strokeWidth ]);

    const controls = useAnimation();

    useEffect(() => {
        let isMount = true
        if (!inView) {
            controls.stop()
            return
        }
        const seq1 = async () => {
            isMount && inView && await controls.start((i) => ({
                d: unitPathAnimatingValues,
                // rotate: `${((360 / unitsNumber) * i) - (180 / unitsNumber)}`,
                transition: {
                    duration: animTimeMs / 1000,
                    delay: (animTimeMs / unitsNumber) * i / 1000
                }
            }));
            isMount && inView && await controls.start((i) => ({
                opacity: 0,
                transition: {
                    duration: animTimeMs / 1000,
                    delay: (animTimeMs / unitsNumber) * i / 1000
                }
            }));
            // controls.start(i => ({
            //     d: unitPathAnimatingValues,
            //     // rotate: ((360 / unitsNumber) * i) - (180 / unitsNumber),
            //     transition: { 
            //         duration: animTimeMs / 1000, 
            //     delay: (animTimeMs / unitsNumber) * i / 1000 }
            // }));
        }
        seq1()
        return () => {
            isMount = false
            controls.stop()
        }
    }, [ inView, controls, animTimeMs, unitsNumber, unitPathAnimatingValues ]);

    return (
        <SvgCenteredWithAppliedViewboxAndScaledSize viewBoxHeight={viewBoxHeight} viewBoxWidth={viewBoxWidth} xScaleFactor={xScaleFactor} yScaleFactor={yScaleFactor} transform={transform} style={style}>

            <g id='sparkGroupId' ref={refWrap}>
                {unitsArray.map((_, i) => {
                    const rotation = ((360 / unitsNumber) * i) - (180 / unitsNumber);
                    const iTimeMs = (animTimeMs / unitsNumber) * i;
                    return (
                        <motion.path
                            key={i}
                            d={unitD}
                            stroke={color}
                            strokeLinecap="round"
                            strokeWidth={strokeWidth}
                            fill="none"
                            custom={i}
                            animate={controls}
                            transform={`rotate(${rotation} ${vbWidthHalf} ${vbHeightHalf})`}
                            style={{
                                // transformOrigin: `${vbWidthHalf}px ${vbHeightHalf}px`,
                                // rotate: 0,
                                // originX: vbWidthHalf,
                                // originY: vbHeightHalf
                                opacity: 1,
                            }}
                            id={`sparkPathId${i}`}
                        />
                    )
                })}
            </g>
        </SvgCenteredWithAppliedViewboxAndScaledSize>
    );
})
