export const defaultHouseVariations = [
    { wallWidth: 60, wallHeight: 90, strokeWidth: 1, wallColor: 'darksalmon' },
    { wallWidth: 65, wallHeight: 80, strokeWidth: 2, wallColor: 'orange' },
    { wallWidth: 62, wallHeight: 70, strokeWidth: 3, wallColor: 'antiquewhite' },
    { wallWidth: 65, wallHeight: 60, strokeWidth: 1, wallColor: 'green' },
    { wallWidth: 62, wallHeight: 50, strokeWidth: 2, wallColor: 'indianred' },
    { wallWidth: 60, wallHeight: 40, strokeWidth: 3, wallColor: 'darkgrey' },

];

export const defaultHouseConstantOptions = {
    roofColor: 'maroon',
    roofStrokeColor: 'white',
    windowColor: 'lightblue',
    windowHeight: 20,
    windowWidth: 20,
    frameColor: 'black',
    chimneyHeight: 20,
    chimneyWidth: 10,
    chimneyColor: "#795548",
    chimneyStrokeColor: "#A95548",
    doorWidth: 15,
    doorHeight: 30,
    doorColor: "brown",
    handleColor: "#FFEB3B",
    // hw: 30,
    // unitsNumber: 8,
    // strokeWidth: 8,
    doorAnimTimeMs: 300,
    doorAnimDelayMs: 300,
    smokeAnimTimeMs: 500,
    smokeAnimDelayMs: 500,
    smokeColor: "#aaa",
    animTimeMs: 2000,
    animDelayMs: 2000,
    viewBoxWidth: 100,
    viewBoxHeight: 100,
    xScaleFactor: 1, // 0.5,
    yScaleFactor: 1, // 0.5,
    // timeToDisappearMs: 3500,
}

export const defaultHouseConstantOptionsWithSeconds = {
    roofColor: 'maroon',
    roofStrokeColor: 'white',
    windowColor: 'lightblue',
    windowHeight: 20,
    windowWidth: 20,
    frameColor: 'black',
    chimneyHeight: 20,
    chimneyWidth: 10,
    chimneyColor: "#795548",
    chimneyStrokeColor: "#A95548",
    doorWidth: 15,
    doorHeight: 30,
    doorColor: "brown",
    handleColor: "#FFEB3B",
    // hw: 30,
    // unitsNumber: 8,
    // strokeWidth: 8,
    doorAnimTimeS: 0.3,
    doorAnimDelayS: 0.3,
    smokeAnimTimeS: 0.5,
    smokeAnimDelayS: 0.5,
    smokeColor: "#aaa",
    animTimeS: 0.5,
    animDelayS: 0.5,
    viewBoxWidth: 100,
    viewBoxHeight: 100,
    xScaleFactor: 0.5,
    yScaleFactor: 0.5,
    // timeToDisappearMs: 3500,
}