export const defaultSparkVariations = [
    { unitsNumber: 5, strokeWidth: 5, color: 'red' },
    { unitsNumber: 6, strokeWidth: 6, color: 'orange' },
    { unitsNumber: 7, strokeWidth: 7, color: 'yellow' },
    { unitsNumber: 8, strokeWidth: 8, color: 'green' },
    { unitsNumber: 9, strokeWidth: 9, color: 'blue' },
    { unitsNumber: 10, strokeWidth: 10, color: 'indigo' },

];

export const defaultSparkConstantOptions = {
    color: 'random',
    hw: 30,
    unitsNumber: 8,
    strokeWidth: 8,
    animTimeMs: 400,
    viewBoxWidth: 100,
    viewBoxHeight: 100,
    xScaleFactor: 0.2,
    yScaleFactor: 0.2,
    // timeToDisappearMs: 3500,
}

