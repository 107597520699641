import React from 'react'

function Leaf({ vbWidthHalf, vbHeightHalf, stemHeight, leafWidth, leafHeight, stemColor, path }) {
    return (
        <path
            d={path}
            stroke={stemColor}
            strokeLinecap="round"
            strokeWidth="1"
            fill="none"
        />
    );
}

export default Leaf